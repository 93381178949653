import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useIdToken } from 'react-firebase-hooks/auth';
import { useEffect } from 'react';
import { auth } from '../firebase';
import { usersTypes } from './enums';

const atoms = {
  showLoginModal: atom(false),
  userType: atom(usersTypes.CLIENT),
  userPhone: atom(''),
  lastNotification: atom({}),
  cookie: atomWithStorage('cookieAccepted', false),
};

const useShowLoginModal = () => useAtom(atoms.showLoginModal);
const useLastNotification = () => useAtom(atoms.lastNotification);
const useCookieAccepted = () => useAtom(atoms.cookie);

const useUser = () => {
  const [user, loading] = useIdToken(auth); // [user, loading, error]
  const [userType, setUserType] = useAtom(atoms.userType);
  const [userPhone, setUserPhone] = useAtom(atoms.userPhone);

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) {
      user.getIdTokenResult(true).then((idTokenResult) => {
        setUserType(idTokenResult.claims.role);
        setUserPhone(idTokenResult.claims.phone);
      });
    }
  }, [user]);

  return {
    user, userType, userPhone, setUserType,
  };
};

export {
  useShowLoginModal,
  useLastNotification,
  useCookieAccepted,
  useUser,
};
