/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './ProfilePage.css';
import apiCall from '../../api';
import classNames from '../../lib/classNames';
import enums from '../../lib/enums';
import translations from '../../lib/translations';
import { useUser } from '../../lib/atoms';
import texts from '../../lib/texts';

function Services() {
  const lang = 'ka';

  const { user, userType } = useUser();

  const [carNumber, setCarNumber] = useState('');

  const [experience] = useState('');
  const [carType, setCarType] = useState(enums.carType.SEDAN);

  const [serviceList, setServiceList] = useState(null);
  const [expandedService, setExpandedService] = useState(-1);

  const [pic, setPic] = useState();

  const [addServiceModal, setAddServiceModal] = useState(false);
  const [driverPhone, setDriverPhone] = useState('');

  const [confirmModal, setConfirmModal] = useState(false);
  const [onConfirmModal, setOnConfirmModal] = useState([() => { }, []]);

  const getServiceInfoRequest = async () => {
    try {
      const { services } = await apiCall(
        '/get-services-info',
        {
          uid: user.uid,
          idToken: user.accessToken,
        },
      );
      setServiceList(services);
    } catch (error) {
      console.error('Error getting service!', error);
    }
  };

  const addServiceRequest = async (index) => {
    console.log('add request');

    try {
      const data = {
        carNumber,
        experience,
        carType,
        userType,
        ...(userType === 'company' && { phone: driverPhone }),
        uid: user.uid,
        serviceAdded: index,
        idToken: user.accessToken,
      };

      await apiCall('/addService', data);
      getServiceInfoRequest();
      setAddServiceModal(false);
    } catch (error) {
      console.error('Error adding service', error);
    }
  };

  const updateServiceRequest = async (index) => {
    console.log('update request');

    try {
      const { data, id } = serviceList[index];
      const {
        sub_battery,
        sub_tyre,
        sub_locked_door,
        sub_fuel,
        sub_sober,
      } = data;

      const dataToUpdate = {
        uid: user.uid,
        serviceId: id,
        sub_battery,
        sub_tyre,
        sub_locked_door,
        sub_fuel,
        sub_sober,
        idToken: user.accessToken,
      };

      const { message } = await apiCall(
        '/update-service',
        dataToUpdate,
      );
      console.log('uhuu');
      console.log(message);
      getServiceInfoRequest();
    } catch (error) {
      console.error('Error updating service', error);
    }
  };

  const deleteServiceRequest = async (index) => {
    console.log('update request');

    try {
      const data = {
        userType,
        uid: user.uid,
        serviceId: serviceList[index].id,
        idToken: user.accessToken,
      };

      const response = await apiCall(
        '/deleteService',
        data,
      );
      console.log('uhuu');
      console.log(response);
      getServiceInfoRequest();
    } catch (error) {
      console.error('Error deleting service', error);
    }
  };

  const addPictureRequest = async (index) => {
    console.log('add img request', index);
    // img
    const formData = new FormData();
    formData.append('pic', pic);

    try {
      const data = {
        carNumber: serviceList[index].data.carNumber,
        experience: serviceList[index].data.experience,
        carType: serviceList[index].data.carType,
        userType,
        uid: user.uid,
        driverId: serviceList[index].data.driverId,
        serviceId: serviceList[index].id,
        pic,
        formData,
        idToken: user.accessToken,
      };
      const response = await apiCall('/addPicture', data, 'multipart/form-data');
      console.log('Add picture success:', response);
      getServiceInfoRequest();
    } catch (error) {
      console.error('Error adding picture:', error);
    }
  };

  const deletePictureRequest = async (index, url) => {
    console.log('add img request', index);
    try {
      const data = {
        uid: user.uid,
        serviceId: serviceList[index].id,
        idToken: user.accessToken,
        url,
      };
      const response = await apiCall(
        '/deletePicture',
        data,
      );
      console.log('Delete picture success:', response);
      getServiceInfoRequest();
    } catch (error) {
      console.error('Error deleting picture:', error);
    }
  };

  const changeServiceStatusRequest = async (index) => {
    try {
      const data = {
        status: !serviceList[index].data.status,
        driverId: serviceList[index].data.driverId,
        serviceId: serviceList[index].id,
        uid: user.uid,
        idToken: user.accessToken,
        userType,
      };
      const response = await apiCall(
        '/changeServiceStatus',
        data,
      );
      console.log('Change service status success:', response);
      getServiceInfoRequest();
    } catch (error) {
      console.error('Error changing service status:', error);
    }
  };

  useEffect(() => {
    getServiceInfoRequest();
  }, []);

  console.log(serviceList);

  return (
    <div>
      {serviceList && serviceList.map((item, index) => (
        <div key={index} style={{ padding: '15px' }} className={expandedService === index ? 'col-lg-8 col-md-12 col-sm-12' : 'col-lg-4 col-md-6 col-sm-12'}>
          <div
            style={{
              backgroundColor: '#F7F7F7',
              padding: '45px',
              paddingBottom: '15px',
              position: 'relative',
            }}
          >
            <div className={classNames.FROM_GROUP_ROW}>
              <span style={{ fontSize: 30 }}>{item.data.carNumber}</span>
            </div>

            <div className={classNames.FROM_GROUP_ROW}>
              <span>
                Type:
                {' '}
                {texts.carType[item.data.carType][lang]}
              </span>
            </div>

            <div className={classNames.FROM_GROUP_ROW}>
              {item.data.verified && (
                <span>
                  Verified
                  {' '}
                  <a className={classNames.BTN_BTN_DEFAULT_BTN_CIRCLE}>
                    <i className="fa fa-check" />
                  </a>
                </span>
              )}
              {!item.data.verified && (
                <span>
                  Not Verified
                  {' '}
                  <a className={classNames.BTN_BTN_DEFAULT_BTN_CIRCLE}>
                    <i className="fa fa-times" />
                  </a>
                  {' '}
                </span>
              )}
            </div>

            {/* <div className={classNames.FROM_GROUP_ROW}>
                            <span>Experience: {item.data.experience}</span>
                        </div> */}

            <div className={classNames.FROM_GROUP_ROW}>
              <span>
                Phone:
                {' '}
                {item.data.phone}
              </span>
            </div>

            <div className={classNames.FROM_GROUP_ROW}>
              <span>
                {translations.profile.services.REVIEWS[lang]}
                :
                {' '}
                {item.data.reviewsAvarage}
                {' '}
                (
                {item.data.reviews}
                )
              </span>
            </div>

            <div
              className={classNames.FROM_GROUP_ROW}
              style={{ marginLeft: 0 }}
            >
              <div className={classNames.FORM_CHECK_FROM_SWITCH}>
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="customSwitchEnabled"
                  checked={item.data.status}
                  onChange={(event) => {
                    setServiceList((prevState) => {
                      const updatedServiceList = [...prevState];
                      updatedServiceList[index].data.status = event.target.checked;
                      return updatedServiceList;
                    });
                    changeServiceStatusRequest(index);
                  }}
                />
                <label
                  className="form-check-label"
                  htmlFor="customSwitchEnabled"
                >
                  {item.data.status ? 'enabled' : 'disabled'}
                </label>
              </div>
            </div>

            {expandedService === index
            && (
            <>
              <h4 className="text-dark">Sub services</h4>
              {[
                'sub_battery',
                'sub_tyre',
                'sub_locked_door',
                'sub_fuel',
                'sub_sober',
              ].map((k) => (
                <div
                  className={classNames.FROM_GROUP_ROW}
                  style={{ marginLeft: 0 }}
                >
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={item.data[k]}
                      name={k}
                      id={k}
                      required
                      checked={item.data[k]}
                      onChange={(e) => {
                        setServiceList((prevState) => {
                          const updatedServiceList = [...prevState];
                          updatedServiceList[index].data[k] = e.target.checked;
                          return updatedServiceList;
                        });
                      }}
                    />
                    <label className="form-check-label" htmlFor={item[k]}>
                      {texts.subProblems[k][lang]}
                    </label>
                  </div>
                </div>
              ))}
              <div
                className={classNames.FROM_GROUP_ROW}
                style={{ marginTop: '2rem' }}
              >
                <div className="form-group col-lg-12" style={{ display: 'flex', justifyContent: 'right' }}>
                  <span
                    className="btn btn-primary btn-modern float-start"
                    onClick={(event) => {
                      event.preventDefault();
                      updateServiceRequest(index);
                    }}
                  >
                    {translations.profile.services.SAVE[lang]}
                  </span>
                </div>
              </div>

              {userType !== enums.usersTypes.DRIVER
              && (
              <>
                <h4 className="text-dark">{translations.profile.services.CERTIFICATES[lang]}</h4>
                <div className="row">
                  {item.data.certificates.map((doc, keyID) => (
                    <div
                      key={keyID}
                      className="col-lg-6 col-md-6 col-sm-6"
                      style={{ padding: '12px' }}
                    >
                      <div className="card">
                        <img className="card-img-top" src={doc.url} alt="doc" />
                        <p className="card-text mb-2 pb-1">{doc.name}</p>
                        <span
                          className="btn btn-danger btn-modern btn-sm float-end"
                          style={{ fontSize: '0.7rem' }}
                          onClick={(event) => {
                            event.preventDefault();
                            setConfirmModal(true);
                            setOnConfirmModal([deletePictureRequest, [index, doc.url]]);
                          }}
                        >
                          delete picture
                        </span>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row">
                  <div className="col-lg-9 col-md-9 col-sm-12" style={{ paddingBottom: '15px' }}>
                    <input
                      className="form-control text-3 h-auto py-2"
                      type="file"
                      accept="image/*"
                      name="url"
                      required
                      onChange={(e) => {
                        setPic(e.target.files[0]);
                      }}
                    />
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-12" style={{ paddingBottom: '15px' }}>
                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                      <span
                        className="btn btn-primary btn-modern btn-md"
                        style={{ fontSize: '0.7rem' }}
                        onClick={(event) => {
                          event.preventDefault();
                          addPictureRequest(index);
                        }}
                      >
                        Add
                      </span>
                    </div>
                  </div>
                </div>

                <div
                  className={classNames.FROM_GROUP_ROW}
                  style={{ marginTop: '2rem' }}
                >
                  <div className="col-sm-12 col-md-4 col-lg-6" />
                  <div className="col-sm-12 col-md-8 col-lg-6">
                    <span
                      className="btn btn-danger btn-modern float-start"
                      style={{ width: '100%' }}
                      onClick={(event) => {
                        event.preventDefault();
                        setConfirmModal(true);
                        setOnConfirmModal([deleteServiceRequest, [index]]);
                      }}
                    >
                      {translations.profile.services.DELETE[lang]}
                    </span>
                  </div>
                </div>
              </>
              )}
            </>
            )}

            <div className="divider divider-primary">
              {expandedService === index && <i className="fas fa-chevron-up text-light" onClick={() => setExpandedService(-1)} />}
              {expandedService !== index && <i className="fas fa-chevron-down text-light" onClick={() => setExpandedService(index)} />}
            </div>
          </div>
        </div>
      ))}

      <Modal show={confirmModal} onHide={() => setConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Are you sure?</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body>{}</Modal.Body> */}
        <Modal.Footer>
          <Button variant="danger" onClick={() => setConfirmModal(false)}>
            No
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setConfirmModal(false);
              onConfirmModal[0](...onConfirmModal[1]);
              setOnConfirmModal([() => { }, []]);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      {addServiceModal && (
      <div style={{ padding: '15px' }} className="col-lg-8 col-md-12 col-sm-12">
        <div
          style={{
            backgroundColor: '#F7F7F7',
            padding: '45px',
            paddingBottom: '15px',
            position: 'relative',
          }}
        >
          <div className={classNames.FROM_GROUP_ROW}>
            <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 required">
              {translations.profile.addService.CAR_NUMBER[lang]}
            </label>
            <div className={classNames.COL_LG_9}>
              <input
                className="form-control text-3 h-auto py-2"
                type="text"
                name="firstName"
                value={carNumber || ''}
                required
                onChange={(e) => setCarNumber(e.target.value)}
              />
            </div>
          </div>
          {/* <div className={classNames.FROM_GROUP_ROW}>
                            <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 required">{translations.profile.addService.DRIVER_EXPERIENCE[lang]}</label>
                            <div className={classNames.COL_LG_9}>
                                <input className="form-control text-3 h-auto py-2" type="text" name="lastName" value={experience || ""} required onChange={(e) => setExperience(e.target.value)} />
                            </div>
                        </div> */}
          <div className={classNames.FROM_GROUP_ROW}>
            <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 required">
              {translations.profile.addService.CAR_TYPE[lang]}
            </label>
            <div className={classNames.COL_LG_9}>
              <select
                className="form-control text-3 h-auto py-2"
                value={carType || ''}
                onChange={(e) => setCarType(e.target.value)}
              >
                {Object.values(enums.carType).map((type) => (
                  <option key={type} value={type}>
                    {texts.carType[type][lang]}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {userType === enums.usersTypes.COMPANY && (
          <div className={classNames.FROM_GROUP_ROW}>
            <label className="col-lg-3 col-form-label form-control-label line-height-9 pt-2 text-2 required">
              {translations.profile.addService.PHONE[lang]}
            </label>
            <div className={classNames.COL_LG_9}>
              <input
                className="form-control text-3 h-auto py-2"
                type="text"
                name="phone"
                value={driverPhone || ''}
                required
                onChange={(e) => setDriverPhone(e.target.value)}
              />
            </div>
          </div>
          )}
          <div className="form-group row">
            <div className="col-sm-12 col-md-4 col-lg-5" />
            <div className="col-sm-6 col-md-4 col-lg-3">
              <span
                className="btn btn-primary btn-modern "
                style={{ width: '100%' }}
                onClick={(event) => {
                  event.preventDefault();
                  addServiceRequest();
                }}
              >
                Add
              </span>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3">
              <span
                className="btn btn-secondary btn-modern"
                style={{ width: '100%' }}

                onClick={(event) => {
                  event.preventDefault();
                  setAddServiceModal(false);
                }}
              >
                Cancel
              </span>
            </div>
          </div>

        </div>
      </div>
      )}

      {((serviceList || []).length === 0
        || userType === enums.usersTypes.COMPANY)
        && userType !== enums.usersTypes.DRIVER
        && !addServiceModal && (
          <div className="container pt-3 pb-2">
            <div className={classNames.FROM_GROUP_ROW}>
              <div className="form-group col-lg-2">
                <span
                  className="btn btn-rounded btn-primary btn-modern float-end"
                  onClick={(event) => {
                    event.preventDefault();
                    setAddServiceModal(true);
                  }}
                >
                  Add service
                </span>
              </div>
            </div>
          </div>
      )}
    </div>
  );
}

export default Services;
