const classNames = {
  MAIN: 'main',
  COL_LG_9: 'col-lg-9',
  NEEDS_VALIDATION: 'needs-validation',
  FROM_GROUP_ROW: 'form-group row',
  BTN_BTN_DEFAULT_BTN_CIRCLE: 'btn btn-default btn-circle',
  COL_SM_9: 'col-sm-9',
  D_BLOCK_PB_2: 'd-block pb-2',
  RATING_INVISIBLE: 'rating-invisible',
  FORM_CHECK_FROM_SWITCH: 'form-check form-switch',
};

export default classNames;

/*
EXAMPLE: "examplle",
*/
