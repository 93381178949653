/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  CircularProgress,
  Backdrop,
  Snackbar,
  Alert,
  IconButton,
} from '@mui/material';
import { DataGrid, GridColumnMenuContainer, GridColumnsMenuItem } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TowTruckRequestDialog from '../../components/TowTruckRequestDialog';
import { usersTypes } from '../../lib/enums';
import { useUser } from '../../lib/atoms';
import apiCall from '../../api';
import CustomPagination from './CustomPagination'; // Import your custom pagination component
import TowTruckViewDialog from '../../components/TowTruckViewDialog';

function CustomColumnMenu(props) {
  const { hideMenu, currentColumn, open } = props;

  if (!currentColumn) {
    return null;
  }

  return (
    <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open={open}>
      <GridColumnsMenuItem column={currentColumn} onClick={hideMenu} />
    </GridColumnMenuContainer>
  );
}

function TowTruckCompany() {
  const { t } = useTranslation();
  const { user, userType } = useUser();
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    towTruckType: '',
    carCount: '',
    carModel: '',
    name: '',
    phone: '',
    transportType: '',
    fromCity: '',
    toCity: '',
    date: dayjs().format('YYYY-MM-DD'),
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // For circular loading
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' }); // Snackbar state
  const [orders, setOrders] = useState([]); // Orders list
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalPages, setTotalPages] = useState(0); // For custom pagination
  const [viewDialogOpen, setViewDialogOpen] = useState(false); // State for the view dialog
  const [selectedOrder, setSelectedOrder] = useState(null); // The selected order data for viewing

  const carType = {
    tow: t('car_type_tow_standard'),
    tow_spider: t('car_type_tow_spider'),
    tow_jumbo: t('car_type_tow_jumbo'),
  };

  const transportaionType = {
    internal: t('order_tow_internal_type'),
    international: t('order_tow_international_type'),
  };

  const statuses = {
    pending: t('order_tow_status_pending'),
    accepted: t('order_tow_status_accepted'),
    rejected: t('order_tow_status_rejected'),
  };

  // Fetch order count
  useEffect(() => {
    if (user?.uid && userType) {
      const getCompanyOrderCountEndpoint = '/get-preorders-paged-count';
      const countData = { idToken: user.accessToken };

      apiCall(getCompanyOrderCountEndpoint, countData).then((response) => {
        setTotalOrders(response.count); // Assuming 'count' field is in the response
        setTotalPages(Math.ceil(response.count / pageSize)); // Calculate total pages
      });
    }
  }, [user?.uid, userType, pageSize]);

  // Fetch orders for the current page
  useEffect(() => {
    if (user?.uid && userType) {
      setLoading(true);
      const getCompanyOrdersEndpoint = '/get-preorders-paged';
      const payLoadData = { page: currentPage - 1, idToken: user.accessToken };

      apiCall(getCompanyOrdersEndpoint, payLoadData).then((response) => {
        setOrders(
          response.preOrders.map((order) => ({
            id: order.id,
            date: new Date(order.created).toLocaleString(),
            type: carType[order.type],
            status: statuses[order.status],
            carAmount: order.carAmount,
            carModel: order.carModel,
            from: order.from,
            to: order.to,
            transportationType: transportaionType[order.transportationType],
            name: order.name,
            phone: order.phone,
          })),
        );
        setLoading(false); // End loading spinner
      });
    }
  }, [user?.uid, userType, currentPage]);

  const handleViewOrder = (order) => {
    setSelectedOrder(order); // Set the selected order for viewing
    setViewDialogOpen(true); // Open the view dialog
  };

  const handleViewDialogClose = () => {
    setViewDialogOpen(false);
    setSelectedOrder(null); // Reset selected order
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setErrors({});
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: null }); // Remove validation errors on change
  };

  const handleSubmit = async () => {
    const newErrors = {};

    // Validate the fields
    if (!formData.towTruckType) newErrors.towTruckType = t('order_tow_validation_required');
    if (!formData.name) newErrors.name = t('order_tow_validation_required');
    if (!formData.phone) newErrors.phone = t('order_tow_validation_required');
    if (!formData.carCount || formData.carCount <= 0) newErrors.carCount = t('order_tow_validation_car_count');
    if (!formData.carModel) newErrors.carModel = t('order_tow_validation_required');
    if (!formData.transportType) newErrors.transportType = t('order_tow_validation_required');
    if (!formData.fromCity) newErrors.fromCity = t('order_tow_validation_required');
    if (!formData.toCity) newErrors.toCity = t('order_tow_validation_required');
    if (!formData.date) newErrors.date = t('order_tow_validation_required');

    if (Object.keys(newErrors).length === 0) {
      setLoading(true); // Start loading spinner

      const createCompanyOrderEndpoint = '/create-preorder';
      let createCompanyOrderData = {};
      if (userType === usersTypes.COMPANY) {
        createCompanyOrderData = {
          idToken: user.accessToken,
          type: formData.towTruckType,
          carAmount: formData.carCount,
          carModel: formData.carModel,
          name: formData.name,
          phone: formData.phone,
          transportationType: formData.transportType,
          from: formData.fromCity,
          to: formData.toCity,
          date: new Date(formData.date).getTime(),
        };
      }

      try {
        const response = await apiCall(createCompanyOrderEndpoint, createCompanyOrderData);
        setSnackbar({ open: true, message: t('order_successful'), severity: 'success' });
        setCurrentPage(1); // Go back to the first page after creating an order
      } catch (error) {
        setSnackbar({ open: true, message: t('order_failed'), severity: 'error' });
      } finally {
        setLoading(false);
        handleClose();
      }
    } else {
      setErrors(newErrors);
    }
  };

  // Handle Snackbar close
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  // DataGrid Columns
  const columns = [
    { field: 'date', headerName: t('tow_order_date'), width: 200 },
    { field: 'type', headerName: t('tow_order_type'), width: 150 },
    { field: 'name', headerName: t('tow_create_order_name'), width: 150 },
    { field: 'phone', headerName: t('tow_create_order_phone'), width: 150 },
    { field: 'carAmount', headerName: t('tow_order_car_amount'), width: 150 },
    { field: 'carModel', headerName: t('tow_order_car_model'), width: 200 },
    { field: 'from', headerName: t('tow_order_from'), width: 150 },
    { field: 'to', headerName: t('tow_order_to'), width: 150 },
    {
      field: 'status',
      headerName: t('tow_order_status'),
      width: 150,
      renderCell: (params) => {
        let backgroundColor;
        switch (params.value) {
          case t('order_tow_status_pending'):
            backgroundColor = 'yellow';
            break;
          case t('order_tow_status_accepted'):
            backgroundColor = 'green';
            break;
          case t('order_tow_status_rejected'):
            backgroundColor = 'red';
            break;
          default:
            backgroundColor = 'grey';
        }

        return (
          <span
            style={{
              backgroundColor,
              color: 'black',
              borderRadius: '4px',
              padding: '4px 8px',
              textAlign: 'center',
              width: '100%',
              display: 'inline-block',
            }}
          >
            {params.value}
          </span>
        );
      },
    },
    { field: 'transportationType', headerName: t('tow_order_transportation_type'), width: 150 },
  ];

  // Conditionally add the actions column only if userType is SUPER
  if (userType === usersTypes.SUPER) {
    columns.push({
      field: 'actions',
      headerName: t('tow_order_actions'),
      width: 100,
      renderCell: (params) => (
        <IconButton
          color="primary"
          onClick={() => handleViewOrder(params.row)}
        >
          <VisibilityIcon />
        </IconButton>
      ),
    });
  }

  return (
    <div>
      {userType === usersTypes.COMPANY && (
        <>
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            {t('request_tow_truck')}
          </Button>

          <TowTruckRequestDialog
            open={open}
            formData={formData}
            errors={errors}
            handleChange={handleChange}
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            carType={carType}
            transportationType={transportaionType}
            t={t}
            loading={loading}
          />

        </>
      )}

      {/* Tow Truck View Dialog */}
      {selectedOrder && (
        <TowTruckViewDialog
          open={viewDialogOpen}
          handleClose={handleViewDialogClose}
          orderData={selectedOrder}
          t={t}
        />
      )}

      {/* Backdrop for Circular Loading Spinner */}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {/* Snackbar for Notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>

      {/* DataGrid for Orders */}
      <div style={{ height: 600, width: '100%', marginTop: 20 }}>
        <DataGrid
          rows={orders}
          columns={columns.map((col) => ({ ...col, sortable: false }))}
          pageSize={pageSize}
          pagination
          rowCount={totalOrders}
          paginationMode="server"
          onPageChange={(newPage) => setCurrentPage(newPage + 1)}
          loading={loading}
          disableSelectionOnClick
          components={{
            Pagination: () => (
              <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={(page) => !loading && setCurrentPage(page)}
                disabled={loading}
                pageSize={pageSize}
                totalItems={totalOrders}
              />
            ),
            ColumnMenu: CustomColumnMenu,
          }}
          sx={{
            backgroundColor: '#212529',
            color: 'white',
            '& .MuiDataGrid-cell': {
              color: 'white',
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: '#004168',
            },
            '& .MuiDataGrid-footerContainer': {
              backgroundColor: '#004168',
            },
          }}
        />
      </div>
    </div>
  );
}

export default TowTruckCompany;
