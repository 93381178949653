/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './ProfilePage.css';
import apiCall from '../../api';
import classNames from '../../lib/classNames';
// import enums from '../../lib/enums';
import translations from '../../lib/translations';
import { useUser } from '../../lib/atoms';
import texts from '../../lib/texts';

function ServiceReview() {
  const lang = 'ka';

  const { user } = useUser();
  const { id } = useParams();
  const [service, setService] = useState(null);

  const [comment, setComment] = useState('');
  const [reviewModal, setReviewModal] = useState(false);

  const updateSerive = () => {
    if (user?.accessToken) {
      const data = {
        serviceId: id,
        idToken: user.accessToken,
      };
      apiCall('/get-service-info-admin', data).then(setService);
    }
  };

  useEffect(() => {
    updateSerive();
  }, [user?.accessToken]);

  if (!service) return (<div />);

  return (
    <div style={{ padding: '15px' }} className="col-lg-8 col-md-12 col-sm-12 text-light">
      <div
        style={{
          padding: '45px',
          paddingBottom: '15px',
          position: 'relative',
          backgroundColor: '#333333',
        }}
      >
        <div className={classNames.FROM_GROUP_ROW}>
          <span style={{ fontSize: 30 }}>{service.carNumber}</span>
        </div>

        <div className={classNames.FROM_GROUP_ROW}>
          <span>
            Type:
            {' '}
            {texts.carType[service.carType][lang]}
          </span>
        </div>

        <div className={classNames.FROM_GROUP_ROW}>
          {service.verified && (
          <span>
            Verified
            {' '}
            <a className={classNames.BTN_BTN_DEFAULT_BTN_CIRCLE}>
              <i className="fa fa-check" />
            </a>
          </span>
          )}
          {!service.verified && (
          <span>
            Not Verified
            {' '}
            <a className={classNames.BTN_BTN_DEFAULT_BTN_CIRCLE}>
              <i className="fa fa-times" />
            </a>
            {' '}
          </span>
          )}
        </div>

        {/* <div className={classNames.FROM_GROUP_ROW}>
                      <span>Experience: {service.experience}</span>
                  </div> */}

        <div className={classNames.FROM_GROUP_ROW}>
          <span>
            Phone:
            {' '}
            {service.phone}
          </span>
        </div>

        <div className={classNames.FROM_GROUP_ROW}>
          <span>
            {translations.profile.services.REVIEWS[lang]}
            :
            {' '}
            {service.reviewsAvarage}
            {' '}
            (
            {service.reviews}
            )
          </span>
        </div>

        <div
          className={classNames.FROM_GROUP_ROW}
          style={{ marginLeft: 0 }}
        >
          <div className={classNames.FORM_CHECK_FROM_SWITCH}>
            <input
              type="checkbox"
              className="form-check-input"
              id="customSwitchEnabled"
              checked={service.status}
              disabled
            />
            <label
              className="form-check-label"
              htmlFor="customSwitchEnabled"
            >
              {service.status ? 'enabled' : 'disabled'}
            </label>
          </div>
        </div>
        <h4 className="text-light">Sub services</h4>
        {[
          'sub_battery',
          'sub_tyre',
          'sub_locked_door',
          'sub_fuel',
          'sub_sober',
        ].map((k) => (
          <div
            className={classNames.FROM_GROUP_ROW}
            style={{ marginLeft: 0 }}
            key={k}
          >
            <div className="form-check text-light">
              <input
                className="form-check-input"
                type="checkbox"
                value={service[k]}
                name={k}
                id={k}
                required
                checked={service[k]}
                disabled
              />
              <label className="form-check-label text-light">
                {texts.subProblems[k][lang]}
              </label>
            </div>
          </div>
        ))}

        <h4 className="text-light">{translations.profile.services.CERTIFICATES[lang]}</h4>
        <div className="row">
          {service.certificates.map((doc, keyID) => (
            <div
              key={keyID}
              className="col-lg-6 col-md-6 col-sm-6"
              style={{ padding: '12px' }}
            >
              <div
                className="card"
                onClick={() => {
                  window.open(doc.url, '_blank');
                }}
                style={{ textAlign: 'center' }}
              >
                <img className="card-img-top" src={doc.url} alt="doc" />
                <p className="card-text mb-1 mt-1 pb-1">{doc.name}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <Button
            variant="primary"
            onClick={() => setReviewModal(true)}
          >
            Review
          </Button>
        </div>
      </div>

      <Modal className="text-center" show={reviewModal} onHide={() => setReviewModal(false)} centered>
        <Modal.Header className="text-light" closeButton>
          <Modal.Title>Verify Service</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>Write reason for driver if rejecting?</h4>
          <input
            type="text"
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            className="form-control form-control-lg text-4 text-light "
            style={{ backgroundColor: '#4f555a' }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              const data = {
                serviceId: id,
                idToken: user.accessToken,
                verified: true,
                comment,
              };
              apiCall('/verify-service-admin', data).then(updateSerive);
              setReviewModal(false);
            }}
          >
            Verify
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              const data = {
                serviceId: id,
                idToken: user.accessToken,
                verified: false,
                comment,
              };
              apiCall('/verify-service-admin', data).then(updateSerive);
              setReviewModal(false);
            }}
          >
            Not Verify
          </Button>
        </Modal.Footer>
      </Modal>
    </div>

  );
}

export default ServiceReview;
