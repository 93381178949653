/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import {
  Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography, CircularProgress, Snackbar, Alert,
} from '@mui/material';
import './ProfilePage.css';
import { useTranslation } from 'react-i18next';

import apiCall from '../../api';
import { useUser } from '../../lib/atoms';

function UserInfo() {
  const { t } = useTranslation();

  const { user, userType } = useUser();

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [nid, setNid] = useState('');
  const [iban, setIban] = useState('');
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // 'success' or 'error'

  useEffect(() => {
    if (user) {
      setLoading(true);
      apiCall('/get-user-info', {
        uid: user.uid,
        idToken: user.accessToken,
      })
        .then((response) => {
          setPhoneNumber(response.phone);
          setEmail(response.email);
          setFirstName(response.firstname);
          setLastName(response.lastname);
          setIban(response.iban);
          setNid(response.nid);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setSnackbarMessage('Failed to load user information');
          setSnackbarSeverity('error');
          setSnackbarOpen(true);
        });
    }
  }, [user]);

  const updateUserRequest = () => {
    setSubmitModalOpen(false);
    setLoading(true);
    apiCall('/update-user-info', {
      email,
      iban,
      nid,
      firstname: firstName,
      lastname: lastName,
      uid: user.uid,
      idToken: user.accessToken,
    })
      .then((r) => {
        setLoading(false);
        setSnackbarMessage('User information updated successfully');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        console.log(r);
      })
      .catch((err) => {
        setLoading(false);
        setSnackbarMessage('Failed to update user information');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
        console.log(err);
      });
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const textFieldStyles = {
    '& .MuiInputBase-root': {
      color: 'white',
    },
    '& .MuiFormLabel-root': {
      color: 'white',
    },
    '& .Mui-disabled': {
      '-webkit-text-fill-color': 'white !important',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: '#0d6efd',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'white',
      },
      '&.Mui-disabled .MuiInputBase-input': {
        color: 'red',
      },
    },
  };

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <Typography variant="body1" style={{ color: 'white' }}>
            {t('profile_first_name')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={firstName || ''}
            onChange={(e) => setFirstName(e.target.value)}
            sx={textFieldStyles}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="body1" style={{ color: 'white' }}>
            {t('profile_last_name')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={lastName || ''}
            onChange={(e) => setLastName(e.target.value)}
            sx={textFieldStyles}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="body1" style={{ color: 'white' }}>
            {t('profile_identification')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={nid || ''}
            onChange={(e) => setNid(e.target.value)}
            sx={textFieldStyles}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <Typography variant="body1" style={{ color: 'white' }}>
            {t('profile_email')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            type="email"
            value={email || ''}
            onChange={(e) => setEmail(e.target.value)}
            disabled={userType === 'company'}
            sx={textFieldStyles}
          />
        </Grid>

        {phoneNumber && (
          <>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1" style={{ color: 'white' }}>
                {t('profile_phone')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={phoneNumber}
                disabled
                sx={textFieldStyles}
              />
            </Grid>
          </>
        )}

        {['company', 'worker'].includes(userType) && (
          <>
            <Grid item xs={12} sm={3}>
              <Typography variant="body1" style={{ color: 'white' }}>
                {t('profile_iban')}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                variant="outlined"
                size="small"
                value={iban || ''}
                onChange={(e) => setIban(e.target.value)}
                sx={textFieldStyles}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={3} />
        <Grid item xs={12} sm={9}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={(event) => {
              event.preventDefault();
              setSubmitModalOpen(true);
            }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : t('profile_save_button')}
          </Button>
        </Grid>
      </Grid>

      <Dialog open={submitModalOpen} onClose={() => setSubmitModalOpen(false)} fullWidth>
        <DialogTitle style={{ color: 'white', backgroundColor: '#333' }}>
          {t('profile_modal_header')}
        </DialogTitle>
        <DialogContent style={{ backgroundColor: '#333' }}>
          {/* Modal content can be added here */}
        </DialogContent>
        <DialogActions style={{ backgroundColor: '#333' }}>
          <Button onClick={() => setSubmitModalOpen(false)} color="secondary">
            {t('profile_modal_no')}
          </Button>
          <Button onClick={updateUserRequest} color="primary">
            {t('profile_modal_yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default UserInfo;
