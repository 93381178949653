/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
const readableId = (s = '', mod = 1000000) => {
  let hash = 0;
  let i; let
    chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = ((hash * 269) + chr) % mod;
  }
  return hash;
};

export default {
  readableId,
};
