/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DataGrid, GridColumnMenuContainer, GridColumnsMenuItem } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import apiCall from '../../api';
import { paymentStatuses, usersTypes, problemType } from '../../lib/enums';
import { useUser } from '../../lib/atoms';
import utils from '../../lib/utils';
import consts from '../../lib/consts';
import CustomPagination from './CustomPagination';
import OrderViewDialog from '../../components/OrderViewDialog';

const mapUserTypeOrderRole = {
  [usersTypes.CLIENT]: 'clientId',
  [usersTypes.WORKER]: 'driverId',
  [usersTypes.DRIVER]: 'driverId',
  [usersTypes.COMPANY]: 'serviceOwnerId',
};

function CustomColumnMenu(props) {
  const { hideMenu, currentColumn, open } = props;

  if (!currentColumn) {
    return null;
  }

  return (
    <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open={open}>
      <GridColumnsMenuItem column={currentColumn} onClick={hideMenu} />
    </GridColumnMenuContainer>
  );
}

function OrderList() {
  const { t } = useTranslation();
  const { user, userType } = useUser();

  const [orders, setOrders] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [reportUrl, setReportUrl] = useState('');
  const [totalOrders, setTotalOrders] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(false);

  const [selectedOrder, setSelectedOrder] = useState(null); // For storing the selected order
  const [viewDialogOpen, setViewDialogOpen] = useState(false); // For managing the dialog state
  // console.log(orders);
  const problemTypes = {
    [problemType.TOW_JUMBO]: t('problem_type_tow_3t'),
    [problemType.TOW_SPIDER]: t('problem_type_tow_spider'),
    [problemType.TOW]: t('problem_type_evacuator'),
    [problemType.BATTERY]: t('problem_type_accumulator'),
    [problemType.TYRE]: t('problem_type_tire'),
    [problemType.LOCKED_DOOR]: t('problem_type_close_door'),
    [problemType.FUEL]: t('problem_type_fuel'),
    [problemType.SOBER]: t('problem_type_driver'),
  };

  useEffect(() => {
    if (user?.uid && userType) {
      setLoading(true);
      const countEndpoint = '/get-orders-all-paged-count';
      const countData = userType === usersTypes.SUPER
        ? { idToken: user.accessToken }
        : { role: mapUserTypeOrderRole[userType], idToken: user.accessToken };

      apiCall(countEndpoint, countData).then((response) => {
        const orderCount = response.count;
        setTotalOrders(orderCount);
        setTotalPages(Math.ceil(orderCount / pageSize));
        setLoading(false);
      });
    }
  }, [user?.uid, userType]);

  useEffect(() => {
    if (user?.uid && userType) {
      setLoading(true);
      const endpoint = userType === usersTypes.SUPER ? '/get-orders-all-paged' : '/get-orders';
      const data = userType === usersTypes.SUPER
        ? { page: currentPage - 1, idToken: user.accessToken }
        : { role: mapUserTypeOrderRole[userType], page: currentPage - 1, idToken: user.accessToken };

      apiCall(endpoint, data).then((response) => {
        if (response?.orders || response.length > 0) {
          const ordersData = response.orders || response;
          setOrders(ordersData.map((order) => ({
            id: order.id,
            date: new Date(order.created).toLocaleString(),
            type: problemTypes[order.problemType],
            status: order.status,
            price: order.tx?.amount || '',
            driverPhone: order.driverPhone,
            clientPhone: order.userPhone,
            rating: order.review?.rating || '',
            paymentStatus: order.paymentStatus,
          })));
        }
        setLoading(false);
      });
    }
  }, [user?.uid, userType, currentPage]);

  const handleDateRangePickerCallback = (start, end) => {
    setReportUrl(`${consts.BACKEND_URL}/download-report?from=${start.valueOf()}&to=${end.valueOf()}`);
  };

  const downloadReport = () => {
    apiCall('/get-one-time-token', { idToken: user.accessToken }).then((response) => {
      window.open(`${reportUrl}&token=${response.token}`, '_blank');
    });
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order); // Set the selected order to be viewed
    setViewDialogOpen(true); // Open the view dialog
  };

  const columns = [
    {
      field: 'id',
      headerName: '#',
      width: 150,
      renderCell: (params) => (
        <Link to={`/order/${params.value}`} className="nav-link text-light">
          {utils.readableId(params.value)}
        </Link>
      ),
    },
    { field: 'date', headerName: t('orders_date'), width: 200 },
    { field: 'type', headerName: t('orders_type'), width: 150 },
    { field: 'status', headerName: t('orders_status'), width: 150 },
    { field: 'price', headerName: t('orders_price'), width: 150 },
  ];

  // Conditionally add columns for 'driverPhone' and 'clientPhone' for SUPER users
  if (userType === usersTypes.SUPER) {
    columns.push(
      { field: 'driverPhone', headerName: t('orders_driver_phone'), width: 150 },
      { field: 'clientPhone', headerName: t('orders_client_phone'), width: 150 },
      { field: 'rating', headerName: t('orders_rating'), width: 150 },
      { field: 'paymentStatus', headerName: t('orders_payment_status'), width: 150 },
    );
  }

  columns.push({
    field: 'actions',
    headerName: t('orders_actions'),
    width: 100,
    renderCell: (params) => (
      <IconButton color="primary" onClick={() => handleViewOrder(params.row)}>
        <VisibilityIcon />
      </IconButton>
    ),
  });

  return (
    <div style={{ height: 600, width: '100%' }}>
      {userType === usersTypes.CLIENT && (
        <section className="container pt-1 pb-1 text-center">
          <Link to="/order" className="btn btn-rounded btn-primary btn-modern">
            {t('orders_new_order_button')}
          </Link>
        </section>
      )}
      <DataGrid
        rows={orders}
        columns={columns.map((col) => ({ ...col, sortable: false }))}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        onPageChange={(newPage) => setCurrentPage(newPage + 1)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[20]}
        page={currentPage - 1}
        loading={loading}
        disableSelectionOnClick
        components={{
          Pagination: () => (
            <CustomPagination
              totalPages={totalPages}
              currentPage={currentPage}
              onPageChange={(page) => !loading && setCurrentPage(page)}
              disabled={loading}
              pageSize={pageSize}
              totalItems={totalOrders}
            />
          ),
          ColumnMenu: CustomColumnMenu,
        }}
        sx={{
          backgroundColor: '#212529',
          color: 'white',
          '& .MuiDataGrid-cell': { color: 'white' },
          '& .MuiDataGrid-columnHeaders': { backgroundColor: '#004168' },
          '& .MuiDataGrid-footerContainer': { backgroundColor: '#004168' },
        }}
      />

      {/* Dialog for Viewing Order */}
      {selectedOrder && (
        <OrderViewDialog
          open={viewDialogOpen}
          orderData={selectedOrder}
          handleClose={() => setViewDialogOpen(false)}
          t={t}
        />
      )}

      {userType === usersTypes.SUPER && (
        <section className="container pt-3 pb-5 text-center">
          <DateRangePicker onCallback={handleDateRangePickerCallback}>
            <input style={{ minWidth: '200px', marginRight: '20px' }} />
          </DateRangePicker>
          <a href="#" className="btn btn-rounded btn-primary btn-modern" onClick={downloadReport}>
            {t('orders_download_button')}
          </a>
        </section>
      )}
    </div>
  );
}

export default OrderList;
