/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import './LoginModal.css';
import styled from 'styled-components';
import { useCookieAccepted } from '../../lib/atoms';

// submit button modal
const ModalStyle = {
  Bckg: styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0vh;
    left: 0vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
  `,
};

function CookieModal() {
  const [cookie, setCookie] = useCookieAccepted();

  if (cookie) return <></>;

  return (
    <>
      <ModalStyle.Bckg />
      <div
        id="LoginModal"
        style={{
          zIndex: '99999', backgroundColor: '#212529', width: '80vw', maxWidth: '500px',
        }}
      >
        <div style={{
          width: '70vw', maxWidth: '450px',
        }}
        >
          <form
            action="/"
            id="frmSignIn"
            method="post"
            className="needs-validation"
            style={{ marginTop: '10px' }}
          >

            <div className="row">
              <div className="form-group col">
                <label className="form-label text-light text-5">
                  ქუქიების პოლიტიკა
                </label>
                <p className=" text-light" style={{ textAlign: '' }}>
                  ჩვენ ვიყენებთ ქუქიებს ჩვენს ვებსაიტზე თქვენი დათვალიერების გამოცდილების გასაუმჯობესებლად და პერსონალიზებული შინაარსის უზრუნველსაყოფად. ამ საიტის გამოყენების გაგრძელებით თქვენ ეთანხმებით ქუქიების გამოყენებას, როგორც ეს აღწერილია ჩვენს
                  {' '}
                  <a href="https://docs.sosauto.ge/privacy-policy/" style={{ color: 'rgb(254, 197, 12)' }}>კონფიდენციალურობის პოლიტიკაში</a>
                  .
                </p>
              </div>
            </div>

            <div className="row">
              <div className="form-group col">
                <button
                  type="submit"
                  onClick={(event) => {
                    event.preventDefault();
                    setCookie(true);
                  }}
                  className="btn btn-modern w-100 text-uppercase rounded-0 font-weight-bold text-3 py-3 text-black"
                  data-loading-text="Loading..."
                  style={{ backgroundColor: 'rgb(254, 197, 12)' }}
                >
                  ვეთანხმები
                </button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </>
  );
}

export default CookieModal;
