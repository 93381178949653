/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
/* eslint-disable no-shadow */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-multi-spaces */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/no-danger */
/* eslint-disable no-tabs */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState } from 'react';
import './LoginModal.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { logInWithEmailAndPassword } from '../../firebase';
import C from '../../lib/consts';
import { useShowLoginModal, useUser } from '../../lib/atoms';
import { usersTypes } from '../../lib/enums';

// submit button modal
const ModalStyle = {
  Bckg: styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0vh;
    left: 0vw;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
  `,
};

function LoginModal() {
  const { userType, setUserType } = useUser();
  const [, setShowLoginModal] = useShowLoginModal();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [code, setCode] = useState('');
  const [codeInputShow, setCodeInputShow] = useState(false);
  const [signinModal, setSigninModal] = useState(true);
  const [registerModal, setRegisterModal] = useState(false);

  const navigate = useNavigate();

  // validation for mob number input
  const handleChange = (event) => {
    const regex = /^[0-9]*$/; // Only allow numbers
    const inputValue = event.target.value;
    if (regex.test(inputValue)) {
      setPhoneNumber(inputValue);
    }
  };
  const CheckNumberCode = () => {
    axios({
      method: 'post',
      url: `${C.BACKEND_URL}/login`,
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        phone: phoneNumber,
        code,
        userType,
      },
    })
      .then((response) => {
        const { email, password } = response.data;
        if (email && password) {
          logInWithEmailAndPassword(email, password);
          navigate('profile');
          setShowLoginModal(false);
        }
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };

  const getcodeRequest = () => {
    axios({
      method: 'post',
      url: `${C.BACKEND_URL}/getCode`,
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        phone: phoneNumber,
      },
    })
      .then((response) => {
        console.log('uhuu');
        console.log(response);
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });

    setCodeInputShow(true);
  };

  const loginCompany = async () => {
    if (await logInWithEmailAndPassword(email, password)) {
      navigate('profile');
      setShowLoginModal(false);
    } else {
      // alert('User not found. Please register to continue.');
    }
  };

  const registerCompany = async () => {
    axios({
      method: 'post',
      url: `${C.BACKEND_URL}/register-company`,
      responseType: 'json',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      data: {
        email,
        password,
      },
    })
      .then((response) => {
        console.log(response);
        loginCompany();
      })
      .catch((error) => {
        console.error('There was an error!', error);
      });
  };

  return (
    <>
      <ModalStyle.Bckg />
      <div id="LoginModal" style={{ zIndex: '99999', backgroundColor: '#212529' }}>
        <span
          className="loginModal-close-icon"
          onClick={(event) => {
            event.preventDefault();
            setShowLoginModal(false);
          }}
        >
          <span />
          <span />
        </span>
        <div className="col-lg-10 col-md-10 col-sm-10">
          <span className="font-weight-bold text-5 mb-0 text-light">
            {signinModal ? 'Login' : 'Registration'}
          </span>
          <span>
            {userType === usersTypes.WORKER && ' as Driver'}
            {userType === usersTypes.COMPANY && ' as Company'}
          </span>
          <form
            action="/"
            id="frmSignIn"
            method="post"
            className="needs-validation"
            style={{ marginTop: '10px' }}
          >
            {userType !== usersTypes.COMPANY && (
              <>
                <div className="row">
                  <div className="form-group col">
                    <label className="form-label text-light text-3">
                      Phone
                      {' '}
                      <span className="text-color-danger">*</span>
                    </label>
                    <input
                      type="text"
                      value={phoneNumber}
                      onChange={(e) => handleChange(e)}
                      className="form-control form-control-lg text-4 text-light"
                      required
                    />
                  </div>
                </div>
                {codeInputShow && (
                  <div className="row">
                    <div className="form-group col">
                      <label className="form-label text-color-dark text-3 text-light">
                        Code
                        {' '}
                        <span className="text-color-danger">*</span>
                      </label>
                      <input
                        type="text"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        className="form-control form-control-lg text-4 text-light"
                        required
                      />
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="form-group col">
                    <button
                      type="submit"
                      onClick={(event) => {
                        event.preventDefault();
                        codeInputShow ? CheckNumberCode() : getcodeRequest();
                      }}
                      className="btn btn-dark btn-modern w-100 text-uppercase rounded-0 font-weight-bold text-3 py-3 text-light"
                      data-loading-text="Loading..."
                      style={{ backgroundColor: '#4f555a' }}
                    >
                      Login
                    </button>
                  </div>
                </div>
              </>
            )}
            {userType === usersTypes.COMPANY && signinModal && (
              <>
                <div className="row">
                  <div className="form-group col">
                    <label className="form-label text-3 text-light">
                      Email
                      {' '}
                      <span className="text-color-danger">*</span>
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control form-control-lg text-4 text-light"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label className="form-label text-3 text-light">
                      Password
                      {' '}
                      <span className="text-color-danger">*</span>
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control form-control-lg text-4 text-light"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <button
                      type="submit"
                      onClick={(event) => {
                        event.preventDefault();
                        loginCompany();
                      }}
                      className="btn btn-dark btn-modern w-100 text-uppercase rounded-0 font-weight-bold text-3 py-3 text-light"
                      data-loading-text="Loading..."
                      style={{ backgroundColor: '#4f555a' }}
                    >
                      Login
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        setSigninModal(false);
                        setRegisterModal(true);
                      }}
                      className="read-more font-weight-semibold text-2 text-light"
                    >
                      Register now
                    </a>
                  </div>
                </div>
              </>
            )}
            {userType === usersTypes.COMPANY && registerModal && (
              <>
                <div className="row">
                  <div className="form-group col">
                    <label className="form-label text-light text-3">
                      Email
                      {' '}
                      <span className="text-color-danger">*</span>
                    </label>
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control form-control-lg text-4 text-light"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <label className="form-label text-3 text-light">
                      Password
                      {' '}
                      <span className="text-color-danger">*</span>
                    </label>
                    <input
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="form-control form-control-lg text-4 text-light"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <button
                      type="submit"
                      onClick={(event) => {
                        event.preventDefault();
                        registerCompany();
                      }}
                      className="btn btn-dark btn-modern w-100 text-uppercase rounded-0 font-weight-bold text-3 py-3 text-light"
                      data-loading-text="Loading..."
                      style={{ backgroundColor: '#4f555a' }}
                    >
                      Register
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group col">
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        setSigninModal(true);
                        setRegisterModal(false);
                      }}
                      className="read-more font-weight-semibold text-2 text-light"
                    >
                      Login
                    </a>
                  </div>
                </div>
              </>
            )}

            {[
              { t: usersTypes.CLIENT, text: 'Login as Client' },
              { t: usersTypes.WORKER, text: 'Login as Driver' },
              { t: usersTypes.COMPANY, text: 'Login as Company' },
            ].map(({ t, text }) => {
              if (t === userType) return <></>;
              return (
                <>
                  <a href="#" onClick={() => { setUserType(t); }} className="read-more font-weight-semibold text-2 text-light">
                    {text}
                    <i className="fas fa-angle-right position-relative top-1 ms-1" />
                  </a>
                  <br />
                </>
              );
            })}
          </form>
        </div>
      </div>
    </>
  );
}

export default LoginModal;
