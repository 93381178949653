/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { DataGrid, GridColumnMenuContainer, GridColumnsMenuItem } from '@mui/x-data-grid';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useUser } from '../../lib/atoms';
import apiCall from '../../api';
import CustomPagination from './CustomPagination';
import ViewUsersOrdersDialog from '../../components/ViewUsersOrdersDialog';

function CustomColumnMenu(props) {
  const { hideMenu, currentColumn, open } = props;

  if (!currentColumn) {
    return null;
  }

  return (
    <GridColumnMenuContainer hideMenu={hideMenu} currentColumn={currentColumn} open={open}>
      <GridColumnsMenuItem column={currentColumn} onClick={hideMenu} />
    </GridColumnMenuContainer>
  );
}

const orderIdentifyFields = {
  client: 'clientId',
  driver: 'driverId',
  worker: 'serviceOwnerId',
  company: 'serviceOwnerId',
};

function UserList() {
  const { t } = useTranslation();
  const { user, userType } = useUser();

  const [users, setUsers] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(false);
  const [userToDelete, setUserToDelete] = useState(null);
  const [userToDeleteInput, setUserToDeleteInput] = useState('');
  const [viewOrdersDialogOpen, setViewOrdersDialogOpen] = useState(false); // State for the dialog
  const [userOrders, setUserOrders] = useState([]); // State for fetched user orders

  // Fetch total user count
  useEffect(() => {
    if (user?.uid && userType) {
      apiCall('/get-all-users-paged-count', { idToken: user.accessToken })
        .then((response) => {
          setTotalUsers(response.count); // Assuming the response has a 'count' field
        })
        .catch((error) => {
          console.error('Error fetching user count:', error);
        });
    }
  }, [user?.uid, userType]);

  // Fetch users for the current page
  const fetchUsers = (page = 0) => {
    setLoading(true);
    apiCall('/get-all-users-paged', { idToken: user.accessToken, page })
      .then((response) => {
        const fetchedUsers = response.users || [];
        setUsers(fetchedUsers.map((usr, index) => ({ ...usr })));
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching users:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (user?.uid && userType) {
      fetchUsers(currentPage - 1);
    }
  }, [user?.uid, userType, currentPage, pageSize]);

  // Fetch orders for a specific user
  // Updated fetchUserOrders to handle role, userId, and page changes properly
  const fetchUserOrders = async (role, userId, page = 1) => {
    console.log('rodis shemodis', role, userId, page);
    try {
    // Fetch the total number of orders for the user
      const specificUserOrdersCountEndpoint = '/get-orders-all-paged-count';
      const specificUserOrdersCountPayload = {
        idToken: user.accessToken,
        role,
        roleId: userId,
      };

      const countResponse = await apiCall(specificUserOrdersCountEndpoint, specificUserOrdersCountPayload);
      const totalOrders = countResponse.count; // Assuming the response contains the total count of orders

      // Fetch the orders for the current page
      const specificUserOrdersEndpoint = '/get-orders-all-paged';
      const specificUserOrdersPayload = {
        idToken: user.accessToken,
        role,
        roleId: userId,
        page: page - 1, // The API expects zero-based pages
      };

      const ordersResponse = await apiCall(specificUserOrdersEndpoint, specificUserOrdersPayload);
      const orders = ordersResponse || [];

      // Set the user orders and open the dialog
      setUserOrders({
        orders,
        totalOrders,
        currentPage: page,
        role,
        roleId: userId,
      });
      setViewOrdersDialogOpen(true); // Open the dialog after fetching data
    } catch (error) {
      console.error('Error fetching user orders:', error);
    }
  };

  const handleDeleteUser = () => {
    if (userToDeleteInput.toLowerCase() === 'delete') {
      setUserToDelete({ ...userToDelete, loading: true });
      apiCall('/delete-user', {
        idToken: user.accessToken,
        deleteUid: userToDelete.id,
      }).then(() => {
        fetchUsers(currentPage - 1);
        setUserToDelete(null);
      });
    }
  };

  const columns = [
    { field: 'email', headerName: t('user_list_email'), width: 200 },
    { field: 'firstname', headerName: t('user_list_first_name'), width: 150 },
    { field: 'lastname', headerName: t('user_list_last_name'), width: 150 },
    { field: 'iban', headerName: t('user_list_iban'), width: 150 },
    { field: 'nid', headerName: t('user_list_nid'), width: 150 },
    { field: 'phone', headerName: t('user_list_phone'), width: 150 },
    { field: 'userType', headerName: t('user_list_user_type'), width: 150 },
    {
      field: 'createTime',
      headerName: t('user_list_create_time'),
      width: 200,
      valueFormatter: (params) => {
        const date = new Date(params.value); // Convert timestamp to milliseconds
        return date.toLocaleString(); // Format date to a readable format
      },
    },
    {
      field: 'actions',
      headerName: t('user_list_actions'),
      width: 150,
      renderCell: (params) => (
        <>
          {/* View Orders Button */}
          <IconButton
            color="primary"
            onClick={() => fetchUserOrders(orderIdentifyFields[params.row.userType], params.row.id)}
          >
            <VisibilityIcon />
          </IconButton>

          {/* Delete User Button */}
          <IconButton
            color="error"
            onClick={() => {
              setUserToDelete(params.row);
              setUserToDeleteInput('');
            }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  return (
    <div style={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={users}
        columns={columns.map((col) => ({ ...col, sortable: false }))}
        pageSize={pageSize}
        pagination
        paginationMode="server"
        rowCount={totalUsers}
        onPageChange={(newPage) => setCurrentPage(newPage + 1)}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        rowsPerPageOptions={[20]}
        page={currentPage - 1}
        loading={loading}
        disableSelectionOnClick
        components={{
          Pagination: () => (
            <CustomPagination
              totalPages={Math.ceil(totalUsers / pageSize)}
              currentPage={currentPage}
              onPageChange={(page) => !loading && setCurrentPage(page)}
              disabled={loading}
              pageSize={pageSize}
              totalItems={totalUsers}
            />
          ),
          ColumnMenu: CustomColumnMenu,
        }}
        componentsProps={{
          pagination: {
            disabled: loading,
          },
        }}
        sx={{
          backgroundColor: '#212529',
          color: 'white',
          '& .MuiDataGrid-cell': {
            color: 'white',
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: '#004168',
          },
          '& .MuiDataGrid-footerContainer': {
            backgroundColor: '#004168',
          },
        }}
      />

      {/* Modal for deleting user */}
      <Modal show={!!userToDelete} onHide={() => setUserToDelete(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'white' }}>{t('user_list_delete_title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!userToDelete?.loading && (
            <>
              <h5>
                {t('user_list_delete_text1')}
                <span style={{ color: '#dc3545' }}> DELETE </span>
                {t('user_list_delete_text2')}
              </h5>
              <input
                type="text"
                value={userToDeleteInput}
                onChange={(event) => setUserToDeleteInput(event.target.value)}
                className="form-control form-control-lg"
                style={{ backgroundColor: '#4f555a' }}
              />
            </>
          )}

          {userToDelete?.loading && (
            <div className="text-center">
              <Spinner animation="border" variant="primary" />
            </div>
          )}
        </Modal.Body>
        {!userToDelete?.loading && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setUserToDelete(null)}>
              {t('user_list_delete_cancel_button')}
            </Button>
            <Button variant="danger" onClick={handleDeleteUser}>
              {t('user_list_delete_button')}
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      {/* Dialog for viewing user orders */}
      <ViewUsersOrdersDialog
        open={viewOrdersDialogOpen}
        handleClose={() => setViewOrdersDialogOpen(false)}
        ordersData={userOrders} // Ensure this holds the correct data structure
        fetchOrdersPage={fetchUserOrders}
        t={t}
      />
    </div>
  );
}

export default UserList;
