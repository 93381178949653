/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import apiCall from '../../api';

import { useUser } from '../../lib/atoms';
import texts from '../../lib/texts';

function VerificationRequests() {
  const lang = 'ka';
  const { user } = useUser();
  const [services, setServices] = useState([]);

  useEffect(() => {
    if (user?.accessToken) {
      const data = {
        idToken: user.accessToken,
      };
      apiCall('/get-verified-services', data).then(setServices);
    }
  }, [user?.accessToken]);

  return (
    <div>
      <div className="container pt-3 pb-2">
        <div className="row pt-2">
          <div className="col-lg-12">
            <table className="table table-striped text-light">
              <thead>
                <tr>
                  <th>
                    Car number
                  </th>
                  <th>
                    Type
                  </th>
                </tr>
              </thead>
              <tbody>
                {services.map((s) => (
                  <tr>
                    <td>
                      <Link to={`/service-review/${s.id}`} className="text-light">{s.carNumber}</Link>
                    </td>
                    <td className="text-light">
                      <Link to={`/service-review/${s.id}`} className="text-light">{texts.carType[s.carType][lang]}</Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  );
}

export default VerificationRequests;
