module.exports = {
  statuses: {
    OK: 'OK',
    ERROR: 'ERROR',
    WARNING: 'WARNING',
  },
  usersTypes: {
    WORKER: 'worker',
    DRIVER: 'driver',
    COMPANY: 'company',
    CLIENT: 'client',
    SUPER: 'super',
  },
  services: {
    status: {
      ENABLED: true,
      DISABLED: false,
    },
    states: {
      FREE: 'free',
      IN_ORDER: 'inOrder',
    },
  },
  orderStatuses: {
    REQUESTED: 'requested',
    ASSIGNED_OPTIONS: 'assigned_options',
    SERVICE_SELECTED: 'service_selected',
    SERVICE_SELECTED_PAYED: 'service_selected_payed',
    SERVICE_SELECTED_PAYMENT_REJECTED: 'service_selected_payment_rejected',
    DRIVER_APPROVED: 'driver_approved',
    CANCELED_BY_DRIVER: 'canceled_by_driver',
    CANCELED_BY_CLIENT: 'canceled_by_client',
    COMPLETED: 'completed',
  },
  carType: {
    SEDAN: 'sedan',
    TOW: 'tow',
    TOW_SPIDER: 'tow_spider',
    TOW_JUMBO: 'tow_jumbo',
  },
  problemType: {
    TOW_JUMBO: 'tow_jumbo',
    TOW_SPIDER: 'tow_spider',
    TOW: 'tow',
    BATTERY: 'battery',
    TYRE: 'tyre',
    LOCKED_DOOR: 'locked_door',
    FUEL: 'fuel',
    SOBER: 'sober',
  },
  paymentStatuses: {
    NOT_PAYED: 'not_payed',
    BLOCKED: 'blocked',
    PAYED: 'payed',
    REFUNDED: 'refunded',
  },
  bogPaymentStatus: {
    created: 'created', // - გადახდის მოთხოვნა შექმნილია
    processing: 'processing', // - გადახდა მუშავდება
    completed: 'completed', // - გადახდის პროცესი დასრულდა წარმატებით
    rejected: 'rejected', // - გადახდის პროცესი დასრულდა წარუმატებლად
    refundRequested: 'refund_requested', // - მოთხოვნილია თანხის დაბრუნება
    refunded: 'refunded', // - გადახდის თანხა დაბრუნებულია
    refundedPartially: 'refunded_partially', // - გადახდის თანხა ნაწილობრივ დაბრუნებულია.
  },
};
