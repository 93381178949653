import axios from 'axios';
import C from '../lib/consts';

const BASE_URL = C.BACKEND_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
});

// multipart/form-data

const apiCall = async (path, data, ct = 'application/json') => {
  const response = await axiosInstance.post(path, data, {
    headers: {
      'Content-Type': ct,
    },
  });
  return response.data;
};

export default apiCall;
