/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/react-in-jsx-scope */

import { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import apiCall from '../../api';
import { useUser } from '../../lib/atoms';
// import texts from '../../lib/texts';
// import utils from '../../lib/utils';

function PaymentMethods() {
  // const lang = 'ka';
  const { user } = useUser();
  const [defaultPmId, setDefaultPmId] = useState(null);
  const [pms, setPms] = useState([]);
  const [confirmModal, setConfirmModal] = useState(false);
  const [toDelete, setToDelete] = useState(null);
  const [forceReload, setForceReload] = useState(0);

  const updateDefault = (pmId) => {
    setDefaultPmId(pmId);
    apiCall('/make-payment-method-default', { idToken: user.accessToken, paymentMethodId: pmId })
      .then(() => setForceReload(forceReload + 1))
      .catch(() => setForceReload(forceReload + 1));
  };

  const deletePm = (pmId) => {
    setPms((cpms) => cpms.filter((fpm) => fpm.parent_order_id !== pmId));
    apiCall('/delete-payment-method', { idToken: user.accessToken, paymentMethodId: pmId })
      .then(() => setForceReload(forceReload + 1))
      .catch(() => setForceReload(forceReload + 1));
  };

  useEffect(() => {
    if (user?.uid) {
      apiCall('/get-payment-methods', { idToken: user.accessToken }).then((response) => {
        if (response?.defaultPaymentMethodId) {
          setDefaultPmId(response.defaultPaymentMethodId);
          setPms(response.paymentMethods);
        }
      });
    }
  }, [user?.uid, forceReload]);

  return (
    <div>
      <div className="container pt-3 pb-2">
        <div className="row pt-2">
          <div className="col-lg-12">
            <Table bordered hover>
              <thead>
                <tr>
                  <th>
                    Card
                  </th>
                  <th>Default</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {pms.map((pm) => (
                  <tr key={pm.cardIdentifier}>
                    <td style={{ width: '100%' }}>
                      {pm.cardIdentifier}
                    </td>
                    <td>
                      <Form.Check // prettier-ignore
                        type="switch"
                        checked={pm.parent_order_id === defaultPmId}
                        onChange={(event) => {
                          if (event.target.checked) { updateDefault(pm.parent_order_id); }
                        }}
                      />
                    </td>
                    <td>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => {
                          setToDelete(pm);
                          setConfirmModal(true);
                        }}
                      >
                        delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Modal show={confirmModal} onHide={() => setConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Delete
            {' '}
            {toDelete?.cardIdentifier}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => setConfirmModal(false)}>
            No
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setConfirmModal(false);
              deletePm(toDelete?.parent_order_id);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default PaymentMethods;
