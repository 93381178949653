import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Box, CircularProgress, Typography, Container, Paper,
} from '@mui/material';
import {
  FacebookShareButton, LinkedinShareButton, WhatsappShareButton,
  FacebookIcon, LinkedinIcon, WhatsappIcon,
} from 'react-share';

import SiteHeader from '../../components/header/SiteHeader';
import SiteFooter from '../../components/footer/SiteFooter';

import C from '../../lib/consts';

function BlogPost() {
  const backendUrl = C.BACKEND_URL;
  const { id } = useParams();
  const [blogData, setBlogData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [shareMetadata, setShareMetadata] = useState(null);

  useEffect(() => {
    const fetchBlogData = async () => {
      try {
        const response = await fetch(`${backendUrl}/get-blog/${id}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setBlogData(data);

        // Prepare sharing metadata
        const imageUrl = data?.blog?.images[0];
        const absoluteImageUrl = imageUrl?.startsWith('http')
          ? imageUrl
          : `${window.location.origin}${imageUrl}`;

        const description = data?.blog?.data?.content
          ?.replace(/<[^>]+>/g, '') // Remove HTML tags
          ?.substring(0, 160)
          ?.trim();

        setShareMetadata({
          title: data?.blog?.data?.title || 'Blog Post',
          description: description || 'Read our latest blog post',
          image: absoluteImageUrl,
          url: window.location.href,
        });
      } catch (error) {
        console.error('Error fetching blog data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlogData();
  }, [id, backendUrl]);

  return (
    <>
      {!loading && blogData && shareMetadata && (
        <Helmet>
          <title>{shareMetadata.title}</title>
          <meta name="description" content={shareMetadata.description} />

          {/* Open Graph / Facebook */}
          <meta property="og:site_name" content="SOS AUTO" />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={shareMetadata.url} />
          <meta property="og:title" content={shareMetadata.title} />
          <meta property="og:description" content={shareMetadata.description} />
          <meta property="og:image" content={shareMetadata.image} />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />

          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@YourTwitterHandle" />
          <meta name="twitter:url" content={shareMetadata.url} />
          <meta name="twitter:title" content={shareMetadata.title} />
          <meta name="twitter:description" content={shareMetadata.description} />
          <meta name="twitter:image" content={shareMetadata.image} />
        </Helmet>
      )}

      <SiteHeader />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {loading ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: '50vh',
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h4" component="h1" gutterBottom sx={{ fontSize: 28, fontWeight: 600, mb: 3 }}>
              {blogData?.blog?.data?.title}
            </Typography>

            {/* Social Share Buttons */}
            {shareMetadata && (
              <Paper
                elevation={0}
                sx={{
                  mb: 4,
                  // p: 2,
                  backgroundColor: 'transparent',
                  display: 'flex',
                  gap: 2,
                  alignItems: 'center',
                }}
              >
                {/* <Typography variant="body2" sx={{ mr: 2 }}>
                  Share:
                </Typography> */}

                <FacebookShareButton url={shareMetadata.url} quote={shareMetadata.title}>
                  <FacebookIcon size={32} round />
                </FacebookShareButton>

                <LinkedinShareButton url={shareMetadata.url} title={shareMetadata.title}>
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>

                <WhatsappShareButton url={shareMetadata.url} title={shareMetadata.title}>
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </Paper>
            )}

            <Box sx={{ position: 'relative', minHeight: 400 }}>
              <Box
                component="img"
                src={blogData?.blog?.images[0]}
                alt={blogData?.blog?.data?.title}
                sx={{
                  width: '40%',
                  height: 'auto',
                  borderRadius: '5px',
                  objectFit: 'cover',
                  float: 'left',
                  marginRight: 3,
                  marginBottom: 2,
                }}
              />
              <Box
                sx={{
                  lineHeight: 1.6,
                  fontSize: 16,
                }}
                dangerouslySetInnerHTML={{ __html: blogData?.blog?.data?.content }}
              />
            </Box>
          </>
        )}
      </Container>
      <SiteFooter />
    </>
  );
}

export default BlogPost;
