const translations = {
  header: {
    HOME: {
      ka: 'home',
    },
    ABOUT: {
      ka: 'about',
    },
    SERVICES: {
      ka: 'services',
    },
    PRODUCTS: {
      ka: 'products',
    },
    BLOG: {
      ka: 'blog',
    },
    APPOINTMENT: {
      ka: 'appointment',
    },
    CONTACT: {
      ka: 'contact',
    },
  },
  profile: {
    sidebar: {
      MY_PROFILE: {
        ka: 'My Profile',
      },
      MY_SERVICES: {
        ka: 'My services',
      },
      BILLING: {
        ka: 'Billing',
      },
      INVOICES: {
        ka: 'Invoices',
      },
      LOGOUT: {
        ka: 'Logout',
      },
    },
    userInfo: {
      USER_PROFILE: {
        ka: 'Profile',
      },
      FIRST_NAME: {
        ka: 'First name',
      },
      LAST_NAME: {
        ka: 'Last name',
      },
      EMAIL: {
        ka: 'Email',
      },
      SUBMIT: {
        ka: 'submit',
      },
      USER_TYPE: {
        ka: 'userType',
      },
    },
    services: {
      SERVICES: {
        ka: 'services',
      },
      CERTIFICATES: {
        ka: 'certificates',
      },
      OWNER_ID: {
        ka: 'ownerId',
      },
      REVIEWS: {
        ka: 'reviews',
      },
      REVIEW_AVARAGE: {
        ka: 'review average',
      },
      VERIFIED: {
        ka: 'verified',
      },
      STATUS: {
        ka: 'status',
      },
      SUB_BATTERY: {
        ka: 'sub_battery',
      },
      SUB_TYRE: {
        ka: 'sub_tyre',
      },
      SUB_LOCKED_DOOR: {
        ka: 'sub_locked_door',
      },
      SUB_FUEL: {
        ka: 'sub_fuel',
      },
      SUB_TOW: {
        ka: 'sub_tow',
      },
      CODE_INPUT_SHOW: {
        ka: 'codeInputShow',
      },
      DRIVER_ID: {
        ka: 'driverId',
      },
      IMG_NAME: {
        ka: 'imgName',
      },
      DELETE_PICTURE: {
        ka: 'delete picture',
      },
      ADD_PICTURE: {
        ka: 'add picture',
      },
      SAVE: {
        ka: 'save',
      },
      DELETE: {
        ka: 'delete',
      },
    },
    addService: {
      ADDSERVICE: {
        ka: 'Add service',
      },
      CAR_NUMBER: {
        ka: 'Car number',
      },
      DRIVER_EXPERIENCE: {
        ka: 'Driver experience',
      },
      CAR_TYPE: {
        ka: 'car type',
      },
      PHONE: {
        ka: 'phone',
      },
      SUBMIT: {
        ka: 'submit',
      },
    },
  },
};
export default translations;
/*
Example: {
    ka: "",
    ka: "example"
},
*/
